import {combo} from "./helpers";
import Creative from "../Creative";
import groups from "./groups";

export function getProcessingGroups() {
  return [
    groups.g8777_8698,
  ];
}

export function getCreativesConfigs(
  fileIndex,
  seeds,
  prompt,
  negativePrompt,
  strengths,
  control_weights,
  control_modes,
  faceTemplateChain
) {
  const results = [];
  const positions = [
    {
      group: groups.user_photo,
      template_name: 8815,
      canvas_template_name: 8663,
      mask_template_name: 8664,
      pose_template_name: 8663,
    },
  ];

  positions.forEach((position) => {
    seeds.forEach((seed) => {
      strengths.forEach((strength) => {
        const templateParams = {
          canvas_template_name: position.canvas_template_name,
          mask_template_name: position.mask_template_name,
          pose_template_name: position.pose_template_name,
          seeds: seed,
          strength,
          inpainting_fill: 1,
          cfg_scale: 15,
          pixel_perfects: "true",
          sampler_name: "DPM$$ SDE Karras",
          ctrlnet_modules: "lineart_realistic",
          control_weights,
          control_modes,
          method: "img2img",
          width: 768,
          height: 1024,
          prompt,
          negative_prompt: negativePrompt,
        };
        
        const mainTemplateId = position.template_name;
        const combo = createCombo(fileIndex, faceTemplateChain, templateParams, mainTemplateId);
        combo.setGroup(position.group);
        combo.setExtra("group_1", position.group);
        combo.setExtra("group_2", seed);
        combo.setExtra("group_3", strength);

        results.push(combo);
      });
    });
  });

  return results;
}

function createCombo(fileIndex, faceTemplateChain, templateParamsArg, mainTemplateId) {
  const templateParams = {
    ...templateParamsArg,
    gender: "#0",
  };

  const steps = [
    {id: "gender_classifier"},
  ];

  const fileImages = [{src: "@" + fileIndex}];

  const faceTemplateChainArr = ("" + faceTemplateChain)
    .split(",")
    .map((i) => i.trim())
    .filter((i) => i.length > 0);

  faceTemplateChainArr.forEach((item, index) => {
    const step = {id: item};
    if (index === 0) {
      step.images = fileImages;
    }

    if (index === faceTemplateChainArr.length - 1) {
      step.setAsFile = "preprocessing";
    }

    steps.push(step);
  });

  const mainTemplateStep = {id: mainTemplateId, templateParams};
  if (steps.length === 1) {
    mainTemplateStep.images = fileImages;
  }

  steps.push(mainTemplateStep);

  let nameSegments = [];
  steps.forEach((step, index) => {
    if (index > 0) {
      nameSegments.push(step.id);
    }
  })

  const name = nameSegments.join("_") + ",seed:" + templateParams.seed + `,str:${templateParams.strength}`;
  const item = combo(steps);
  item.setExtra(Creative.EXTRA_NAME, name);

  return item;
}
